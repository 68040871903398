import React, { useState, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import QuikLinks from "../QuickLinks/QuikLinks";
import OtherLinks from '../../OtherLinks/OtherLinks';
import "./alllinks.css";

export default function AllLinks({ links }) {
  return (
    <div className="carrer-main">
      <QuikLinks />
      <Marquee
        className="marquee-container1"
        loop={0}
        pauseOnHover={true}
        autoFill={true}
      >
        <OtherLinks links={links} />
      </Marquee>
    </div>
  );
}
