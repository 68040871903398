import React, { useState, useEffect } from 'react';
import "./stateCo-ordination.css";
import { Col, Container, Row } from "react-bootstrap";
import FloatingLabelSerch from "react-bootstrap/FloatingLabel";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from "react-bootstrap/Form";
import TabArchives from "react-bootstrap/Tab";
import TabsTabArchives from "react-bootstrap/Tabs";
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import APIService from '../../APIService';

export default function StateCoordination() {

  const [scfContent, setScfContent] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [years, setYears] = useState([]);

  useEffect(() => {
    APIService.get('state_coordination')
      .then((response) => {
        setScfContent(response.data);
      });
  }, []);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsList = [];
    for (let i = currentYear; i >= 2001; i--) {
      yearsList.push(i);
    }
    setYears(yearsList);
  }, []);

  const [scfMinutes, setScfMinutes] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    APIService.get('scf_minutes').then((response) => {
      setScfMinutes(response.data);
      setFilteredData(response.data);
    })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    const filteredItems = scfMinutes.filter((data) =>
      data.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);
    const filteredItems = scfMinutes.filter((data) =>
      data.year.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredItems);
  }

  return (
    <div>
      <Container fluid className="StateCoordination">
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className="organisation-head">State Co-ordination Forum</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <TabsTabArchives
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example"
          className="wrapper-scf">

          <TabArchives eventKey="home" title=" SCF">
            <div>
              <Row className="mt-4">
                <Col md={12} className="StateCoordination-main">
                  <div dangerouslySetInnerHTML={{ __html: scfContent }} />
                </Col>
              </Row>
            </div>
          </TabArchives>

          <TabArchives eventKey="SCF Minutes" title="SCF Minutes">
            <div className='scf-search-area'>
              <Row className='reg-row2' id="scf-row">
                <Col md={3} id="year-tab2">
                  <FloatingLabel
                    controlId="floatingSelectGrid"
                    label="">
                    <Form.Select
                      aria-label="Default select example"
                      value={filterYear}
                      onChange={handleYearFilterChange}
                    >
                      <option value="">Year</option>
                      {years.map((year, index) => (
                        <option key={index} value={year}>{year}</option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6} className='form-aling' id='reg-form-align'>
                  <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search.." id="scf-search-tab">
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      value={searchItem}
                      onChange={handleSearchInputChange}
                    />
                  </FloatingLabelSerch>
                  <button type="button" className="btn btn-outline-primary" id='search-btn-reg'>Search</button>
                </Col>
              </Row>
            </div>

            <div className="scf-tabs" >
              <Table striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th className='table-no-aling'>Sr.No. </th>
                    <th>Description</th>
                    <th>Year</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredData.map((scf, index) => (
                    <tr key={"key_" + index}>
                      <td>{index + 1}</td>
                      <td>{scf.description}</td>
                      <td>{scf.year}</td>
                      <td>
                        <div className='ordrs-icon-aling'>
                          <Link to={scf.file} target='blank'>View</Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </Table>
            </div>
          </TabArchives>
        </TabsTabArchives>
      </Container>
    </div >
  );
}