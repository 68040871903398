import React from 'react';

import "./logoname.css";

export default function Logoname() {
  return (
    <div className="logoname">
      <div className='main-logo-bar'>
        <h3 className='malayalam'>കേരള സ്റ്റേറ്റ് ഇലക്ട്രിസിറ്റി റെഗുലേറ്ററി കമ്മീഷൻ</h3>
        <img className='logo-img' src={require("../../../img/kserc_logo.png")} alt='logo-img'/>
        <h3 className='english'>Kerala State Electricity Regulatory Commission</h3>
      </div>
    </div>
  );
}

{/* <NavbarLogo className="main-logo">
        <div className="logo-bar" id="logo-bar">
          <NavbarLogo.Brand to="/" id="malayalam-text" className="malayalam-text">
            കേരള സ്റ്റേറ്റ് ഇലക്ട്രിസിറ്റി റെഗുലേറ്ററി കമ്മീഷൻ
          </NavbarLogo.Brand>
          <NavbarLogo.Brand to="/">
            <img
              className="logo-img" id="logo-img"
              src={require("../../../img/kserc_logo.png")}
            />
          </NavbarLogo.Brand>
          <NavbarLogo.Brand to="/" id="eng-text" className="eng-text">
            Kerala State Electricity Regulatory Commission
          </NavbarLogo.Brand>
          <NavbarLogo.Toggle />
        </div>
      </NavbarLogo> */}