import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TabArchives from 'react-bootstrap/Tab';
import TabsTabArchives from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './orderpage.css';
import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
import { Link } from "react-router-dom";
import APIService from '../../APIService';
import { WidthFull } from '@mui/icons-material';

export default function Orderpage() {

  const orderTypes = [
    "All Orders",
    "Daily Orders",
    "Tariff Orders",
    "ARR Orders",
    "Truing Up Orders",
    "General Orders",
  ];

  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterLicensee, setFilterLicensee] = useState("");

  const [licensees, setLicensees] = useState([]);

  useEffect(() => {
    APIService.get('licensees').then((response) => {
      setLicensees(response.data);
    })
  }, [])


  useEffect(() => {
    APIService.get('orders')
      .then((response) => {
        // console.log(response.data);
        setData(response.data);
        setFilteredData(response.data);
      })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    // filter the items using the apiUsers state
    const filteredItems = data.filter((data) =>
      data.order.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    // filter the items using the apiUsers state
    const filteredItems = data.filter((data) =>
      data.order_date.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  const handleLicenseeFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterLicensee(searchTerm);

    // Filter the items using Licensees for 'petitions'
    var filteredItems = data.filter((data) =>
      data.licensee_id == searchTerm
    );

    setFilteredData(filteredItems)
  }

  // Utility function to generate years dynamically
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year.toString());
    }
    return years;
  }

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Orders</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='wrapper'>
          <Col md={3} id="year-tab">
            <FloatingLabel
              controlId="floatingSelectGrid"
              label=""
            >
              <Form.Select aria-label="Floating label select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {generateYears().map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={3} id="licence-bar">
            <FloatingLabel
              controlId="floatingSelectGrid"
              label=""
            >
              <Form.Select
                aria-label="Default select example"
                value={filterLicensee}
                onChange={handleLicenseeFilterChange}
              >
                <option value="">Select Licensee</option>
                {licensees.map((licensee, index) => (
                  <option key={index} value={licensee.id}>
                    {licensee.heading}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={3} className='form-aling1'>
            <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange} />

            </FloatingLabelSerch>
            </Col>
          
            <Col md={3} id='search-btn1'  style={{width:'250px' ,textAlign: 'center' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
        </Row>
        
      </Container>
    
      <div className='order-wrapper'>
        <Row className='mt-4'>
          <Col md={12} >

            <TabsTabArchives
              defaultActiveKey={orderTypes[0]}
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
            >
              {orderTypes.map((type, index) => (

                <TabArchives
                  eventKey={type}
                  title={type}
                  key={"tab_" + index}
                >
                  <div >
                    <Table striped bordered hover variant="light">
                      <thead>
                        <tr>
                          <th className='table-no-aling'>Sr.No. </th>
                          <th>Order No.</th>
                          <th>Order</th>
                          <th>Date</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>

                        {index === 0 ?
                          filteredData
                            .map((order, index) => (
                              <tr key={order.id}>
                                <td className='table-no-aling'>{index + 1}</td>
                                <td>{order.order_no}</td>
                                <td>{order.order}</td>
                                <td style={{whiteSpace:'nowrap'}}>{order.order_date}</td>
                                <td>
                                  <div className='ordrs-icon-aling'>
                                    <Link to={order.file} target='blank'>view</Link>
                                  </div>
                                </td>
                              </tr>
                            )) :
                          filteredData
                            .filter(order => order.option === type).map((order, index) => (
                              <tr key={order.id}>
                                <td className='table-no-aling'>{index + 1}</td>
                                <td>{order.order_no}</td>
                                <td>{order.order}</td>
                                <td style={{whiteSpace:'nowrap'}}>{order.order_date}</td>
                                <td>
                                  <div className='ordrs-icon-aling'>
                                    <Link to={order.file} target='blank'>view</Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                        }
                      </tbody>
                    </Table>
                  </div>
                </TabArchives>
              ))}
            </TabsTabArchives>
          </Col>
        </Row>
      </div>
    </div>
  )
}
