import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import "./events.css";
import APIService from '../../APIService';

export default function Events() {

  const [eventThumbnails, setEventThumbnails] = useState([]);

  useEffect(() => {
    APIService.get('event_thumbnail')
      .then((response) => {
        setEventThumbnails(response.data);
      });
  }, []);

  return (
    <div>
      <Container fluid className="right-to-information">
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className="organisation-head">Events</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='mt-2'>
          {eventThumbnails.map((item, index) => (
            <Col key={index} xs={12} sm={6} md={6} lg={6}>
              <div className="event-container mt-5">
                <h3>{item.heading}</h3>
                <a href={"/events/" + item.id}>
                  <img
                    className='event-img'
                    src={item.image}
                    alt={`Image ${index}`}
                    style={{ cursor: 'pointer' }} />
                </a>
                <a href={"/events/" + item.id}>View Details</a>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
