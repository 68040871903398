import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './pressrelease.css';
import { Link } from "react-router-dom";
import APIService from '../../APIService';

export default function PressRelease() {

  const [pressReleases, setPressReleases] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");

  useEffect(() => {
    APIService.get('pressrelease')
      .then((response) => {
        setPressReleases(response.data);
        setFilteredData(response.data);
      })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = pressReleases.filter((data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    const filteredItems = pressReleases.filter((data) =>
      data.added_on.includes(searchTerm)
    );

    setSearchItem(""); // Reset search term
    setFilteredData(filteredItems);
  }

  // Generate years dynamically from current year to 2001
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year);
    }
    return years;
  }

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Press Release</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <div className='press-release-search-area'>
        <Row className='reg-row1' id="press-release-search-area">
          <Col md={3}  id="year-tab">
            <FloatingLabel
              controlId="floatingSelectGrid"
              label="">
              <Form.Select
                aria-label="Default select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {generateYears().map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={3} className='form-aling' id='press-form-align'>
            <FloatingLabel controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange}
              />
            </FloatingLabel>
            
          </Col>

         <Col style={{display:'flex', textAlign:'center'}} md={3} > <button type="button" className="btn btn-outline-primary" id='search-btn-reg'>Search</button></Col>
        </Row>
        <div className='press-table'>
          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Title</th>
                <th>Year</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((data, index) => (
                <tr key={data.id}>
                  <td>{index + 1}</td>
                  <td>{data.title}</td>
                  <td>{data.added_on}</td>
                  <td>
                    <div className='ordrs-icon-aling'>
                      <Link to={data.file} target='blank'>View</Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}
