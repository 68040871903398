import React, { useState, useEffect } from 'react';
import './faq.css';
import { Link } from 'react-router-dom';

export default function Faq() {
  return (
    <div className='faq-aling'>
      <Link to='/faqpage'>
        <div className='faq'>
          <p className='faq-text'> FAQ</p>
        </div>
      </Link>
    </div>
  )
}
