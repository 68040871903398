import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import APIService from '../../APIService';
import './events.css';

export const EventImages = () => {
  const params = useParams();
  const thumbnailId = params.id;

  const [eventImages, setEventImages] = useState([]);
  const [thumbnailHeading, setThumbnailHeading] = useState("");

  useEffect(() => {
    APIService.get('event_thumbnail/' + thumbnailId)
      .then((response) => {
        if (response.data.length > 0) {
          setEventImages(response.data);
          let firstItem = response.data.find(element => element !== undefined);
          setThumbnailHeading(firstItem.thumbnail_heading);
        }
      });
  }, []);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  }

  return (
    <div>
      <Container fluid className='right-to-information'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>{thumbnailHeading}</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className='wrapper'>
          {eventImages.map((item, index) => (
            <Col key={index} xs={12} sm={6} md={4}>
              <div className='smart-city-aling'>
                <div className="event-container">
                  <img
                    className='event-img'
                    key={index}
                    src={item.image}
                    alt={`Image ${index}`}
                  />
                  <p className='startup-caption'>{item.caption}</p>
                  <p className='startup-inner-desc'>{item.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
