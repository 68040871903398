const APIService = {
  //  base_url: "https://api.erckerala.org/api",
  // base_url: "https://erckerala.test/api",
  base_url:"https://dev.erckerala.org/api",
// base_url:'https://mal.erckerala.org/api',

  get: async function (endpoint) {
    try {
      const response = await fetch(`${this.base_url}/${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch data from ${endpoint}. Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw new Error(`Failed to fetch data from the server: ${error.message}`);
    }
    
  },

  post: async function (formData, endpoint) {
    try {
      const response = await fetch(`${this.base_url}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json'
        },
        body: formData
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch data from ${endpoint}. Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw new Error(`Failed to fetch data from the server: ${error.message}`);
    }
    
  },
};

export default APIService;

