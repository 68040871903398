import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './GalleryImages.css';
import APIService from '../../APIService';
import { useParams } from 'react-router-dom';

export const GalleryImages = () => {
  const params = useParams();
  const thumbnailId = params.id;

  const [galleryImages, setGalleryImages] = useState([]);
  const [thumbnailHeading, setThumbnailHeading] = useState("");
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    APIService.get('gallery_thumbnail/' + thumbnailId)
      .then((response) => {
        if (response.data.length > 0) {
          setGalleryImages(response.data);
          let firstItem = response.data.find(element => element !== undefined);
          setThumbnailHeading(firstItem.thumbnail_heading);
        }
      });
  }, [thumbnailId]);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  }

  const closeLightbox = () => {
    setIsOpen(false);
  }

  const handleImageLoad = () => {
    setImagesLoaded(true);
  }

  return (
    <div>
      <Container fluid className='right-to-information'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>{thumbnailHeading}</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="gallery-wrapper1">
        <h4>{thumbnailHeading}</h4>
        <Row className='mt-2'>
          {galleryImages.map((gallery, index) => (
            <Col key={index} xs={12} sm={12} md={4} lg={4} className='gallery-column'>
            
                <img
                  className='gallery-img'
                  src={gallery.image}
                  alt={`Image ${index}`}
                  onClick={() => openLightbox(index)}
                  style={{ cursor: 'pointer', margin: '10px' }}
                  onLoad={handleImageLoad} />
                <p>{gallery.caption}</p>
           
            </Col>
          ))}
        </Row>
        {isOpen && imagesLoaded && (
          <Lightbox
            mainSrc={galleryImages[photoIndex].image}
            nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length].image}
            prevSrc={galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length].image}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + galleryImages.length - 1) % galleryImages.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % galleryImages.length)}
          />
        )}
      </div>
    </div>
  );
}
