import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './dashboard.css';
import { Link } from "react-router-dom";
import APIService from '../../APIService';

export default function Dashboard() {

  const [links, setLinks] = useState([]);

  useEffect(() => {
    APIService.get('dashboard_link').then((response) => {
      setLinks(response.data);
    })
  }, []);

  const [petitionsDate, setPetitionsDate] = useState("");
  const [petitionsCount, setPetitionsCount] = useState("");
  const [ordersDate, setOrdersDate] = useState("");
  const [ordersCount, setOrdersCount] = useState("");

  useEffect(() => {
    APIService.get('dashboard_count')
      .then((response) => {
        setPetitionsDate(response.petitions_as_on_date);
        setPetitionsCount(response.petitions_count);
        setOrdersDate(response.orders_as_on_date);
        setOrdersCount(response.orders_count);
      });
  }, []);

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Dashboard</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>

        <div className='dashboard-query' id='dashboard-query'>
          <button id="dash-btn">
            No of Petitions <br /> as on {petitionsDate} <br /><p className="blink-text">{petitionsCount}</p>
          </button>
          <button id="dash-btn">
            Orders issued <br /> as on {ordersDate} <br /><p className="blink-text">{ordersCount}</p>
          </button>
          {/* <div className='left-query'>
            <h6>No of Petitions as on Date <br />100</h6>
          </div>
          <div className='right-query'>
            <h6>Order issued as on Date <br />100</h6>
          </div> */}
        </div>
      </Container>

      <Container className="dashboard-table">
        <Row className='mt-5'>
          <Col md={12} className='mt-2'>
            <Table striped bordered hover variant="light" className='dashboard'>
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Title</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {links.map((link, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{link.title}</td>
                    <td>
                      <div className='ordrs-icon-aling'>
                        <Link to={link.link}>view</Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
