import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import "./Gallery.css";
import APIService from '../../APIService';

export default function Gallery() {

  const [galleryThumbnails, setGalleryThumbnails] = useState([]);

  useEffect(() => {
    APIService.get('gallery_thumbnail')
      .then((response) => {
        setGalleryThumbnails(response.data);
      });
  }, []);

  return (
    <div className="gallery-container">
      <Container fluid className="right-to-information">
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className="organisation-head">Gallery</h1>
            </div>
          </Col>
        </Row>
      </Container>
    
      <Container fluid>
        <Row className="mt-2">
          {galleryThumbnails.map((item, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="gallery-item mt-5">
              <h5>{item.heading}</h5>
              <a href={"/gallery/" + item.id}>
                <img
                  className='gallery-thumbnail'
                  src={item.image}
                  alt={`Image ${index}`}
                />
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
