import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Col, Container, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import APIService from '../../APIService';

export const SearchResults = () => {

  const params = new URLSearchParams(document.location.search)
  // console.log(params.get('query'));

  const [results, setResults] = useState([]);

  useEffect(() => {

    const formData = new FormData();
    formData.append("search", params.get('query'));

    APIService.post(formData, 'search').then((response) => {
      setResults(response.data);
    })
  }, []);

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Search Results</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className='wrapper'>
          <Col md={12} className='mt-2'>
            <Table striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Title</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={"results_row_" + index}>
                    <td>{result.type}</td>
                    <td>{result.text}</td>
                    <td>
                      <div className='ordrs-icon-aling'>
                        <a href={result.link} target='blank'>view</a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
