import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './StateAdvisory.css';
import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TabArchives from 'react-bootstrap/Tab';
import TabsTabArchives from 'react-bootstrap/Tabs';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import APIService from '../../APIService';
import { Link } from 'react-router-dom';

export default function StateAdvisory() {

  const [sacMinutes, setSacMinutes] = useState([]);

  const [stateAdvisory, setStateAdvisory] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [years, setYears] = useState([]);

  useEffect(() => {
    APIService.get('sac_minutes').then((response) => {
      setSacMinutes(response.data);
      setFilteredData(response.data);
    })
  }, []);

  useEffect(() => {
    APIService.get('stateadvisory')
      .then((response) => {
        // console.log(response.stateadvisory);
        setStateAdvisory(response.stateadvisory);
      })
  }, []);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsList = [];
    for (let i = currentYear; i >= 2001; i--) {
      yearsList.push(i);
    }
    setYears(yearsList);
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    // filter the items using the apiUsers state
    const filteredItems = sacMinutes.filter((data) =>
      data.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    // filter the items using the apiUsers state
    const filteredItems = sacMinutes.filter((data) =>
      data.year.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  return (
    <div>
      <Container fluid className='StateAdvisory'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>State Advisory Committee</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='wrapper' id="sac-wrapper">
          <Col md={12} className='mt-2'>
            <TabsTabArchives
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
            >
              <TabArchives eventKey="home" title=" SAC">
                <div >
                  <Table striped bordered hover variant="light">
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Name/Title</th>
                        <th>Address</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stateAdvisory.map((advisory, index) => (
                        <tr key={advisory.id}>
                          <td>{index + 1}</td>
                          <td>{advisory.name}</td>
                          <td>{advisory.address}</td>
                          <td>{advisory.designation}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </TabArchives>

              <TabArchives eventKey="SAC Minutes" title="SAC Minutes">
                <div className='sac-search-area' id='sac-search-area'>
                  <Row className='reg-row-sac' >
                    <Col md={3} id="year-tab-sac">
                      <FloatingLabel
                        controlId="floatingSelectGrid"
                        label="">
                        <Form.Select
                          aria-label="Default select example"
                          value={filterYear}
                          onChange={handleYearFilterChange}
                        >
                          <option value="">Year</option>
                          {years.map((year, index) => (
                            <option key={index} value={year}>{year}</option>
                          ))}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md={6} className='form-aling' id='reg-form-align'>
                      <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search.." id="search-box">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          value={searchItem}
                          onChange={handleSearchInputChange}
                        />
                      </FloatingLabelSerch>
                      <button type="button" className="btn btn-outline-primary" id='search-btn-reg'>Search</button>
                    </Col>
                  </Row>
                </div>
                <div className="minutes-tab">
                  <Table striped bordered hover variant="light">
                    <thead>
                      <tr>
                        <th className='table-no-aling'>Sr.No. </th>
                        <th>Description</th>
                        <th>Year</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((sac, index) => (
                        <tr key={"key_" + index}>
                          <td>{index + 1}</td>
                          <td>{sac.description}</td>
                          <td>{sac.year}</td>
                          <td>
                            <div className='ordrs-icon-aling'>
                              <Link to={sac.file} target='blank'>View</Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </TabArchives>
            </TabsTabArchives>
          </Col>
        </Row>
      </Container>
    </div>
  )
}