import { Col, Container, Row } from "react-bootstrap";
import CarouselNews from "react-bootstrap/Carousel";
import "./newsgallery.css";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import React, { useRef, useState } from "react";
// Import Swiper React components
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, FreeMode } from "swiper/modules";


export default function NewsGallery({ gallery, news }) {

  const limit = 3
  const limitGallery = gallery.slice(0, limit)

  return (
    // <div className="background">
     <Container>
        {/* <div className="gallery-wrapper"> */}
          <Row className="gallery-news">
            <Col md={6} className="slider-wrap" >
              <h3 className="swiper-wrap" id="headings">Gallery</h3>
              <Swiper
                slidesPerView={2}
                spaceBetween={30}
                freeMode={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  type:'fraction',
                  clickable: true,
                  autoplay: true,
                }}
                modules={[Autoplay, Pagination, FreeMode]}
                className="myswiper1"
              >
                {limitGallery.map((link, index) => (
                  <SwiperSlide className="swiper-slide" key={index}>
                    {" "}
                    <img
                      // className="container-gelley-img"
                      src={link}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
  
              <div className="gallery-btn">
                <Link to="/gallery"><button className="btn btn-outline-primary mt-5">View More</button></Link>
              </div>
            </Col>
  
            <Col md={6} className="news-area">
              <h3 className="news-head-1" id="headings">News</h3>
              <CarouselNews interval={3000} className="news-curosel curosel.fade">
                {news.map((n, index) => (
                  <CarouselNews.Item key={"news_carousal_" + index}>
                    <img
                      className="news-img"
                      src={n.image}
                    />
                    <CarouselNews.Caption>
                      <p className="news-text-1">
                        {n.description}
                      </p>
                      <Link to={"/news/" + n.id}>
                        <button type="button" className="btn btn-outline-primary btn-sm" >More Info</button>
                      </Link>
                    </CarouselNews.Caption>
                  </CarouselNews.Item>
                ))}
              </CarouselNews>
            </Col>
          </Row>
        {/* </div> */}
     </Container>
    // </div>
  );
}
