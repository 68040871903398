import React, { useState, useEffect } from 'react';
import { Person } from '@mui/icons-material';
import './navbartop.css';
import { Link } from 'react-router-dom';
import APIService from '../../../APIService';

export default function NavbarTop() {

  const [siteData, setSiteData] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level

  useEffect(() => {
    APIService.get('site_data').then((response) => {
      setSiteData(response);
    });
  }, []);

  useEffect(() => {
    const visitorId = localStorage.getItem("visitorId");

    if (visitorId === null) {
      APIService.post([], 'add_visitor').then((response) => {
        localStorage.setItem("visitorId", response);
      });
    }
  }, []);

  const handleZoomIn = () => {
    if (zoomLevel < 200) {
      setZoomLevel(zoomLevel + 10); // Increase zoom level by 10%
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 50) {
      setZoomLevel(zoomLevel - 10); // Decrease zoom level by 10%
    }
  };

  const resetZoom = () => {
    setZoomLevel(100); // Reset zoom level to 100%
  };

  useEffect(() => {
    document.body.style.zoom = `${zoomLevel}%`; // Apply zoom to the entire page
  }, [zoomLevel]);

  return (
    <div className='NavbarTop'>
      <div className='inner-navbar-top'>
       
        <div className='topnav'>
        <span><div className='line'></div></span>
          <p>Last Updated</p>
          <span><div className='line'></div></span>
          <p>{siteData.site_last_updated}</p>
          {/* <span><div className='line'></div></span>
          <p>12:52</p> */}
          <span><div className='line'></div></span>
          <p>Site Visitors : {siteData.site_visitors_count}</p>
          <span><div className='line'></div></span>
        </div>
        <div className='topnav'>
          <span><div className='line'></div></span>
          <p><a style={{textDecoration:'none',color:'white'}} href='https://www.erckerala.org/'>English</a></p>
          <span><div className='line'></div></span>
          <p><a style={{textDecoration:'none',color:'white'}} href='https://malayalam.erckerala.org/'>മലയാളം</a></p>
          <span><div className='line'></div></span>
          
          <p ><a style={{color:'white',textDecoration:'none',whiteSpace:'nowrap'}} href='http://older.erckerala.org'> Old site</a> </p>
          {/* <span><div className='line'></div></span> */}

          {/* <Person className='icon' /> */}
          <span><div className='line'></div></span>
          <div className="screen_sizer">
            <ul>
              <li>
                <button onClick={handleZoomIn}>A+</button>
              </li>
              <li>
                <button onClick={resetZoom}>A</button> {/* Reset zoom */}
              </li>
              <li>
                <button onClick={handleZoomOut}>A-</button>
              </li>
              
            </ul>
          </div>
          <span><div className='line'></div></span>
        </div>
      </div>
    </div>
  )
}