import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './news.css';
import APIService from '../../APIService';
import { useParams } from 'react-router-dom';

export default function NewsPage() {

  const params = useParams();
  const newsId = params.id;
  const [news, setNews] = useState([]);

  useEffect(() => {
    APIService.get('news/' + newsId)
      .then((response) => {
        setNews(response);
      });
  }, []);

  return (
    <div>
      <Container className='news-page-wrapper'>
        <Row className='mb-3' id="news-heading">
          <h2>{news.heading}</h2>
          <Col md={12}>
            <img className='news-image' src={news.image} />
          </Col>
        </Row>
        <div className='news-desc' dangerouslySetInnerHTML={{ __html: news.description }} />
      </Container>
    </div>
  )
}
