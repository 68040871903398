import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import APIService from '../../../../APIService';

export const OtherErc = () => {
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [filteredData, setFilteredData] = useState([]);


  useEffect(() => {
    APIService.get('other_ercs')
      .then((response) => {
        // console.log(response.data);
        setData(response.data);
        setFilteredData(response.data)
      })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    // filter the items using the apiUsers state
    const filteredItems = data.filter((data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }


  return (
    <div>
      <Container fluid className='StateAdvisory'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Other ERCs</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='wrapper' id='other-docs-search-bar'>
          <Col md={8} className='form-aling'>
            <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Search">
              <Form.Control
               type="text"
               placeholder="Search"
               value={searchItem}
               onChange={handleSearchInputChange} />

            </FloatingLabelSerch>
          </Col>
          <Col md={4}><button type="button" className="btn btn-outline-primary" id='search-btn'>Search</button>
</Col>
        </Row>
      </Container>
      <div className='other-docs-table'>
        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Title</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((data, index) => (
              <tr key={data.id}>
                <td>{index + 1}</td>
                <td><Link to={data.link}>{data.title}</Link></td>
              </tr>
            ))}
            {/* <tr>
              <td>1</td>
              <td> <Link to='http://www.tnerc.gov.in/'>Tamilnadu Electricity Regulatory Commission</Link></td>
            </tr>
            <tr>
              <td>2</td>
              <td> <Link to='https://kerc.karnataka.gov.in/'>Karnataka Electricity Regulatory Commission</Link></td>
            </tr> */}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
