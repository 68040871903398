import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './commissioncard.css';

export default function CommissionCard({ members }) {
  return (
    <div>
      <Container>
        <Row className='mt-3' id='commission-card-container'>
          {members.map((member, index) => (
            <Col md={4} className='commettie' id='commettie' key={index}>
              <h5>{member.heading}</h5>
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img className='card-img-commission' src={member.image} />
                    <h4 className='card-commission-head'>
                      {member.caption}</h4>
                  </div>
                  <div className="flip-card-back">
                    <h3>{member.caption}</h3>
                    <p>{member.heading}</p>
                    <p>
                      {member.description}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
