import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './careerspage.css';
import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import APIService from '../../APIService';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function Careerspage() {

  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [careers, setCareers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    APIService.get('careers').then((response) => {
      setCareers(response.careers);
      setFilteredData(response.careers);
    })
  }, []);

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2001; year--) {
    years.push(year);
  }

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = careers.filter((data) =>
      data.job_title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    const filteredItems = careers.filter((data) =>
      data.last_date.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  return (
    <div>
      <Container fluid className='career'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Careers</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='wrapper'>
          <Col md={4} id="archives-search-area">
            <FloatingLabel
              controlId="floatingSelectGrid"
              label=""
            >
              <Form.Select
                aria-label="Default select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {years.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={4} className='form-aling' id='archives-form'>
            <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange}
              />
            </FloatingLabelSerch>
            {/* <button type="button" className="btn btn-outline-primary" id='search-btn'>Search</button> */}
          </Col>
          <Col md={4} id='search-btn1'  style={{width:'250px' ,textAlign: 'center' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
        </Row>
      </Container>
      <Container>
        <Row className='mt-5'>
          <Col md={12} className='wrapper'>
            <Table striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Last Date</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map(career => (
                  <tr key={career.id}>
                    <td>{career.job_title}</td>
                    <td>{career.description}</td>
                    <td>{career.last_date}</td>
                    <td>
                      <div className='ordrs-icon-aling'>
                        <Link to={career.file} target='blank'>view</Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
