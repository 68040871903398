import React, { useState, useEffect } from 'react';
import './faqpage.css';
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import APIService from '../../APIService';

export default function FaqPage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    APIService.get('faq')
      .then((response) => {
        // console.log(response.data);
        setData(response.data);
      })
  }, []);
  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>FAQs</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className='faq-container'>
        <Accordion defaultActiveKey={['0']} alwaysOpen>
          {data.map((data, index) => (
            <Accordion.Item eventKey="0">
              <Accordion.Header key={data.id}>{data.question}</Accordion.Header>
              <Accordion.Body>
                <div dangerouslySetInnerHTML={{ __html: data.answer }} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>Question 2</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Question 3</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body> */}
        </Accordion>
      </Container>
    </div >
  )
}
