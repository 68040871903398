import React from "react";
import "./otherlinks.css";
import { Col, Row } from "react-bootstrap";

export default function OtherLinks({ links }) {

  // console.log(links);

  return (
    <div className="marquee1">
      <Row className="mt-2">
        <Col className="box">
          {links.map((link, index) => (
            <a href={link.link} target="blank" key={index}>
              <div key={index} className="link-boxes">
                <img
                  className='otherlinks-logo'
                  src={link.logo}
                  alt={`Image ${index}`}
                />
                <span className="link-text">{link.title}</span>
              </div>
            </a>
          ))}
        </Col>
      </Row>
    </div>
  );
}