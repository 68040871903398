import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './startup.css';
import APIService from '../../APIService';

export default function Startup() {

  const [startupThumbnails, setStartupThumbnails] = useState([]);

  useEffect(() => {
    APIService.get('startup_thumbnail')
      .then((response) => {
        setStartupThumbnails(response.data);
      });
  }, []);

  // const startupThumbnails = [
  //   {
  //     heading: "heading1",
  //     thumbnail: "",
  //     image: (require('../../img/Gallery/State-Co-ordination-Forum-3.jpeg')),
  //     description:
  //       "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying",
  //   },
  //   {
  //     heading: "heading2",
  //     caption: "caption",
  //     description:
  //       "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying",
  //     thumb: "thumb2",
  //   },
  // ];
  const [thumbIndex, setthumbIndex] = useState(0);

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Startup Entrepreneurship</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="gallery-wrapper">
        <div className="wrapper">
          <Row className='mt-2'>
            <Col className="event-wrapper">
              {startupThumbnails.map((item, index) => (
                <div className="event-container" key={index}>
                  <h3 className="">{item.heading}</h3>
                  <a href={"/startup/" + item.id}>
                    <img
                      className='event-img'
                      key={index}
                      src={item.image}
                      alt={`Image ${index}`}
                      style={{ cursor: 'pointer', margin: '10px' }} />
                  </a>
                  {/* <h5 className='event-caption'>{item.caption}</h5> */}
                  {/* <p className='event-desc' >{item.description} </p> */}
                  <a href={"/startup/" + item.id}>View Details</a>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
