import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './news.css';
import { Link } from "react-router-dom";
import APIService from '../../APIService';

export const News = () => {

  const [data, setData] = useState([]);
  useEffect(() => {
    APIService.get('news')
      .then((response) => {
        // console.log(response.data);
        setData(response.data);
      })
  }, []);

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>News</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className='news-container'>
        <Row className='wrapper'>
          {data.map((data, index) => (
            <Col md={4} key={index}>
              <h3 className="startup-heading">{data.heading}</h3>
              <a href={"/news/" + data.id}>
                <img className='startup-img' src={data.image} />
              </a>
              <p className='startup-text' > {data.short_description} </p>
              <Link to={"/news/" + data.id}>View Details</Link>
            </Col>
          ))}
          {/* <Col md={4}>
            <img className='startup-img' src={require("../../../src/img/news-img-1.jpg")} />
            <p className='startup-text' > Public hearing on approval cost data 2023 of Distribution works. Petitioner : KSEB Ltd. </p>
            <Link to="/newspage">View Details</Link>
          </Col>
          <Col md={4}>
            <img className='startup-img' src={require("../../../src/img/new-img-2.jpg")} />
            <p className='news-desc' >  Consumer Awareness Programme at Vydyuthi Bhawan, Ponnkunnam Conference Hall-05.07.2023</p>
            <Link to="/newspage2">View Details</Link>
          </Col>
          <Col md={4}>
            <div className='smart-city-aling' >
              <img className='startup-img' src={require("../../../src/img/new-img-3.jpg")} />
              <p className='news-desc' > Consumer Awareness Programme at Infopark, Kakkanad-11.07.2023</p>
              <Link to="/newspage3">View Details</Link>
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  )
}