import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import "./consumer_advocacy.css";
import APIService from '../../APIService';

export default function ConsumerAdvocancy() {

  const [cacContent, setCacContent] = useState("");

  useEffect(() => {
    APIService.get('consumer_advocacy_cell')
      .then((response) => {
        setCacContent(response.data);
      });
  }, []);

  return (
    <div>
      <Container fluid className="StateCoordination">
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className="organisation-head"> Consumer Advocacy & Compliance Examination</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="desc-wrapper">
        <Row className="mt-4">
          <Col md={12} className="advocacy-main">
            <div dangerouslySetInnerHTML={{ __html: cacContent }} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
