import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import APIService from '../../APIService';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import './scheduleofhearing.css'

export default function ScheduleOfHearing() {

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');

  useEffect(() => {
    APIService.get('schedule')
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
  }, []);


  const handleMonthFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterMonth(searchTerm);

    const filteredItems = data.filter((data) =>
      data.month === searchTerm
    );

    setFilteredData(filteredItems);
  };

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    const filteredItems = data.filter((data) =>
      data.year === searchTerm
    );

    setFilteredData(filteredItems);
  }

  // Utility function to generate years dynamically
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year.toString());
    }
    return years;
  }

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Schedule of Hearing</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className='wrapper'>
          <Col  md={4} id="archives-search-area">
            <FloatingLabel
              controlId="floatingSelectGrid"
              label=""
              
            >
              <Form.Select
                aria-label="Default select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {generateYears().map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>

          <Col md={4} style={{display:'flex',justifyContent:'center',marginTop:'10px'}} className='form-aling' id='archives-form'>
            <Form.Select
              aria-label="Default select example"
              value={filterMonth}
              onChange={handleMonthFilterChange}
            >
              <option value="">Month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </Form.Select>
            {/* <button type="button" className="btn btn-outline-primary" id='search-btn1'>Search</button> */}
          </Col>
          <Col md={4}  id='search-btn1'  style={{width:'250px' ,textAlign: 'center' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
        </Row>
      </Container>

      <Container>
        <Row className='wrapper'>
          <Col md={12} className='mt-2'>
            <Table striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Title</th>
                  <th>Year</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.title}</td>
                    <td>{data.year}</td>
                    <td>
                      <div className='ordrs-icon-aling'>
                        <Link to={data.file} target='_blank'>View</Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
