import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import APIService from '../../APIService';
import { useParams } from 'react-router-dom';

export const StartupImages = () => {

  const params = useParams();
  const thumbnailId = params.id;

  const [startupImages, setStartupImages] = useState([]);
  const [thumbnailHeading, setThumbnailHeading] = useState("");

  useEffect(() => {
    APIService.get('startup_thumbnail/' + thumbnailId)
      .then((response) => {

        if (response.data.length > 0) {

          setStartupImages(response.data);
          let firstItem = response.data.find(element => element !== undefined);
          setThumbnailHeading(firstItem.thumbnail_heading);
        }
      });
  }, []);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  }

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>{thumbnailHeading}</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Row className='wrapper'>
        {startupImages.map((item, index) => (
          <Col md={4} key={index}>
            <div className='smart-city-aling' >
              <div className="event-container">
                <img
                  className='event-img'
                  key={index}
                  src={item.image}
                  alt={`Image ${index}`}
                />
                <p className='startup-caption'>{item.caption}</p>
                <p className='startup-inner-desc' >{item.description} </p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}



// div >
//         <div className=".event-container">
//           <Row className='mt-2'>
//             {startupImages.map((item, index) => (
//               <Col className="startup-wrapper" md={4}>
//                 <div className="startup-container">
//                   <img
//                     className='event-img'
//                     key={index}
//                     src={item.image}
//                     alt={`Image ${index}`}
//                   />
//                   <h5 className='startup-caption'>{item.caption}</h5>
//                   <p className='startup-inner-desc' >{item.description} </p>
//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </div>
//       </div>